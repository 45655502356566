<template>
  <div>
    <div class="pa-4">
      <navbar-profile :items="items"></navbar-profile>
    </div>
    <div class="px-5">
      <v-row no-gutters class="" align="center">
        <v-col cols="12" md="4" class="py-2">
          <bullet title="Rekap All Klien"/>
        </v-col>
      </v-row>
      <v-row class="px-5" no-gutters>
        <v-col cols="12" md="3" sm="12">
          <div class="mt-2 pa-2">Rekap Jumlah Total</div>
          <v-row no-gutters>
            <v-col cols="6" md="6" sm="4" class="pa-2">
              <div class="shadow">
                Jumlah Klien Keseluruhan Biro
                <div
                  class="mt-2 d-flex align-center justify-space-between"
                  style="width: 60%"
                >
                  <div style="border: 2px solid #633b18; height: 20px"></div>
                  <h3 v-if="!loading && dataSet != null" class="txt_color_default">
                    {{ this.dataSet.jml_customer?this.dataSet.jml_customer:"0" }}
                  </h3>
                  <v-skeleton-loader
                    v-if="loading"
                    width="50%"
                    height="30px"
                    type="image"
                  ></v-skeleton-loader>
                </div>
              </div>
            </v-col>
            <v-col cols="6" md="6" sm="4" class="pa-2">
              <div class="shadow">
                Jumlah Karyawan
                <div
                  class="mt-2 d-flex align-center justify-space-between"
                  style="width: 60%"
                >
                  <div style="border: 2px solid #633b18; height: 20px"></div>
                  <h3 v-if="!loading && dataSet != null" class="txt_color_default">
                    {{ this.dataSet.karyawan_klien?this.dataSet.karyawan_klien:"0" }}
                  </h3>
                  <v-skeleton-loader
                    v-if="loading"
                    width="50%"
                    height="30px"
                    type="image"
                  ></v-skeleton-loader>
                </div>
              </div>
            </v-col>
            <v-col cols="6" md="6" sm="4" class="pa-2">
              <div class="shadow">
                Jumlah Semua Komunitas
                <div
                  class="mt-2 d-flex align-center justify-space-between"
                  style="width: 60%"
                >
                  <div style="border: 2px solid #633b18; height: 20px"></div>
                  <h3 v-if="!loading && dataSet != null" class="txt_color_default">
                    {{ this.dataSet.jml_community?this.dataSet.jml_community:"0" }}
                  </h3>
                  <v-skeleton-loader
                    v-if="loading"
                    width="50%"
                    height="30px"
                    type="image"
                  ></v-skeleton-loader>
                </div>
              </div>
            </v-col>
            <v-col cols="6" md="6" sm="4" class="pa-2">
              <div class="shadow">
                Jumlah Biro
                <div
                  class="mt-2 d-flex align-center justify-space-between"
                  style="width: 60%"
                >
                  <div style="border: 2px solid #633b18; height: 20px"></div>
                  <h3 v-if="!loading && dataSet != null" class="txt_color_default">
                    {{ this.dataSet.jml_klien?this.dataSet.jml_klien:"0" }}
                  </h3>
                  <v-skeleton-loader
                    v-if="loading"
                    width="50%"
                    height="30px"
                    type="image"
                  ></v-skeleton-loader>
                </div>
              </div>
            </v-col>
          </v-row>
          <div class="mt-4 pa-2">Rekap Event</div>
          <v-row no-gutters>
            <v-col cols="6" md="6" sm="4" class="pa-2">
              <div class="shadow">
                Jumlah Event Berjalan
                <div
                  class="mt-2 d-flex align-center justify-space-between"
                  style="width: 60%"
                >
                  <div style="border: 2px solid #633b18; height: 20px"></div>
                  <h3 v-if="!loading && dataSet != null" class="txt_color_default">
                    {{ this.dataSet.event_berjalan?this.dataSet.event_berjalan:"0" }}
                  </h3>
                  <v-skeleton-loader
                    v-if="loading"
                    width="50%"
                    height="30px"
                    type="image"
                  ></v-skeleton-loader>
                </div>
              </div>
            </v-col>
            <v-col cols="6" md="6" sm="4" class="pa-2">
              <div class="shadow">
                Jumlah Event Selesai
                <div
                  class="mt-2 d-flex align-center justify-space-between"
                  style="width: 60%"
                >
                  <div style="border: 2px solid #633b18; height: 20px"></div>
                  <h3 v-if="!loading && dataSet != null" class="txt_color_default">
                    {{ this.dataSet.event_selesai?this.dataSet.event_selesai:"0" }}
                  </h3>
                  <v-skeleton-loader
                    v-if="loading"
                    width="50%"
                    height="30px"
                    type="image"
                  ></v-skeleton-loader>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="9" sm="12">
          <div class="px-10 py-2 mt-2">Rekap Event Perbulan tahun {{ new Date().getFullYear() }}</div>
          <div v-if="!loading && dataSet != null" class="chart">
            <apexchart type="bar" height="350" :options="chartOptionsBulanan" :series="seriesBulanan"></apexchart>
          </div>
          <div v-if="loading" class="mt-5 px-10">
            <v-skeleton-loader width="100%" height="200px" type="image"></v-skeleton-loader>
          </div>
        </v-col>
      </v-row>
      <div class="px-7 mt-4">Rekap Rata-rata Event Layanan</div>
      <div v-if="!loading && dataSet != null" class="chart">
        <apexchart type="bar" height="350"  :options="chartOptionsLayanan" :series="seriesLayanan"></apexchart>
      </div>
      <div v-if="loading" class="chart">
        <v-skeleton-loader width="100%" height="200px" type="image"></v-skeleton-loader>
      </div>

    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import bullet from "../../components/Etc/bullet.vue";
import NavbarProfile from "../../components/navbarProfile/navbarProfile.vue";
export default {
  name: "rekapAllClient",
  components: { bullet, NavbarProfile },
  computed: {
    ...mapState({}),
  },
  data() {
    return {
      menuLimit: false,
      loading: false,
      dataSet: null,
      loading: false,
      dialogAlert: false,
      d_create: false,
      d_small: false,
      d_type: "",
      d_title: "",
      inventory: "",
      line: "",
      find: "",
      page: 1,
      limit: 10,
      selected: null,
      last_page: null,
      total_page: null,
      sort: "-created_at",
      filter: "",
      items: [
        {
          text: "Home",
          disabled: false,
          href: "/",
        },
        {
          text: "Biro Analytics Semua Klien",
          disabled: true,
          href: "#",
        },
      ],
      seriesLayanan: [{
        name: 'Rekap Rata-rata Event Layanan',
        data: []
      }],
      chartOptionsLayanan: {
        chart: {
          type: 'bar',
          height: 350,
          width: '100%'
        },
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
            dataLabels: {
              position: 'top', // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val + "%";
          },
          offsetY: -20,
          offsetX: 0,
          style: {
            fontSize: '12px',
            colors: ["#304758"]
          }
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: [],
          labels: {
            show: true,
            rotate: -45,
            trim: true,
          }
        },
        yaxis: {
          title: {
            text: 'Rata-rata'
          },
          min:0,
          max:100,
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + " persen"
            }
          }
        },
        responsive: [
          {
            breakpoint: 1000,
            options: {
              plotOptions: {
                bar: {
                  horizontal: false
                }
              },
              legend: {
                position: "bottom"
              }
            }
          }
        ]
      },
      seriesBulanan: [{
        name: 'Rekap Rata-rata Event ' + new Date().getFullYear(),
        data: []
      }],
      chartOptionsBulanan: {
        chart: {
          type: 'bar',
          height: 350,
          width: '100%'
        },
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
            dataLabels: {
              position: 'top', // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val + "%";
          },
          offsetY: -20,
          offsetX: 0,
          style: {
            fontSize: '12px',
            colors: ["#304758"]
          }
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: [],
          labels: {
            show: true,
            rotate: -45,
          }
        },
        yaxis: {
          title: {
            text: 'Rata-rata'
          },
          min:0,
          max:100,
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + " persen"
            }
          }
        },
        responsive: [
          {
            breakpoint: 1000,
            options: {
              plotOptions: {
                bar: {
                  horizontal: false
                }
              },
              legend: {
                position: "bottom"
              }
            }
          }
        ]
      },
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    changeLimit(val) {
      this.limit = val;
      this.fetchData();
    },
    createData() {
      this.d_type = "c_client";
      this.d_title = "Tambah Akun Klien";
      this.d_create = true;
      this.line = true;
    },
    updateData(item) {
      this.inventory = item;
      this.d_type = "u_client";
      this.d_title = "Edit Akun Klien";
      this.d_create = true;
      this.line = true;
    },
    deleteData(item) {
      this.inventory = {
        data: item,
        path: `sysadmin/klien/delete`,
        deleteType: "Client",
      };
      this.d_type = "delete";
      this.d_title = "Hapus Akun Klien";
      this.d_small = true;
      this.line = true;
    },
    changeSort(item) {
      this.sort = item;
      this.fetchData();
    },
    changeFilter(item) {
      this.filter = item;
      this.fetchData();
    },
    fetchData() {
      var arrMonth = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
      this.loading = true;
      let data = {
        path: `sysadmin/biro-analisis/biro-analisis/all`,
      };
      this.$store
        .dispatch("client/getData", data)
        .then((data) => {
          this.chartOptionsLayanan.xaxis.categories = Object.keys(data.average_event_layanan);
          this.chartOptionsBulanan.xaxis.categories = arrMonth;
          Object.keys(data.average_event_layanan).forEach((element, key) => {
            this.seriesLayanan[0].data.push(data.average_event_layanan[element].average);
          });
          arrMonth.forEach((element, key) => {
            if(data.klien_permonth[element]){
              this.seriesBulanan[0].data.push(data.klien_permonth[element].length);
            }else{
              this.seriesBulanan[0].data.push(0);
            }
          });
          this.dataSet = data;
          this.loading = false;
        })
        .catch((e) => {
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.v-pagination__item {
  box-shadow: none !important;
}
.v-pagination__navigation {
  box-shadow: none !important;
}
.shadow {
  box-shadow: 4px 4px 4px 2px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 8px;
  height: 100%;
}
.chart{
  margin: 5px 30px;
  opacity: 0.9
}
</style>
